<template>
  <div class="field">
    <md-card class="card" v-if="resultadoValidacao">
      <h5>Colunas validadas do Lote em pré-execução:</h5>
      <BarChart
        v-for="(item, name) in resultadoValidacao.data"
        :key="name"
        :item="item"
        :name="name"
      />
    </md-card>
    <md-card class="card" v-else-if="errorAlertValidac">
      <div>
        <span class="material-symbols-outlined icon-error task"
          >highlight_off</span
        >
        <h5 class="task errorAlert">{{ errorAlertValidac }}</h5>
      </div>
    </md-card>
    <div v-else>
      <Loading class="spinner" />
    </div>
  </div>
</template>

<script>
import { mapWritableState, mapActions } from "pinia";
import { useBacktestMemoryStore } from "@/stores/backtestMemory";
import { consultasService } from "@/services";
import BarChart from "@/components/Visuais/BarChart.vue";
import Loading from "@/components/Loading";

export default {
  name: "Validacoes",

  components: {
    BarChart,
    Loading,
  },

  props: {
    file: File,
    opcaoPessoaSelecionada: String,
    fileColuns: Array,
    separadorDefinido: String,
    finalHeader: String,
    finalFormat: String,
    projectName: String,
  },

  data() {
    return {
      resultadoValidacao: null,
      errorAlertValidac: "",
    };
  },

  methods: {
    async checkFile() {
      this.resultadoValidacao = null;
      this.errorAlertValidac = "";
      var objetoConsulta = {};

      const rotaProduto =
        this.rotaConsulta == "Box de Modelos" ? "v3/modelo" : "v3/box";

      objetoConsulta["arquivo"] = this.file;
      objetoConsulta["produto"] = rotaProduto;
      objetoConsulta["tipo_pessoa"] = this.opcaoPessoaSelecionada;
      objetoConsulta["cod_ref"] = this.abordagemConsulta;
      objetoConsulta["caracter_separador"] = this.separadorDefinido;
      objetoConsulta["mapeamento_cabecalho"] = this.finalHeader;
      objetoConsulta["mapeamento_formatos"] = this.finalFormat;
      objetoConsulta["ignorar_linhas_duplicadas"] =
        this.ignoraLinhaDuplicadaMemory;
      objetoConsulta["nome_projeto"] = this.projectName;

      try {
        this.resultadoValidacao = await consultasService.validaLote(
          objetoConsulta
        );
        this.$emit("resultadoValid", this.resultadoValidacao);
      } catch (error) {
        this.errorAlertValidac = error.toString();
      }
    },
  },

  created() {
    this.checkFile();
  },

  computed: {
    ...mapWritableState(useBacktestMemoryStore, [
      "ignoraLinhaDuplicadaMemory",
      "abordagemConsulta",
      "rotaConsulta",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.field {
  width: 99%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  // padding: 10px;
  margin: 10px !important;
}
.card {
  padding: 10px;
  margin: 0px !important;
}
.spinner {
  // width: 20px;
  position: absolute;
  left: 70%;
  top: 150px;
  z-index: 1;

  @include mediaQueryMax(small) {
    left: 50%;
    top: 200px;
  }
}
.task {
  display: inline-block;
}
.errorAlert {
  width: 90%;
  margin: 10px 5px 10px 5px !important;
}
.icon-error {
  color: rgba(255, 0, 0, 0.7);
  font-size: 2rem !important;
  margin: 10px 5px 10px 5px !important;
}
</style>