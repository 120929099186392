<template>
  <div class="field">
    <div class="file is-boxed is primary">
      <label class="file-label text-reader">
        <input
          type="file"
          ref="file"
          class="file-input"
          id="child-input"
          name="fileName"
          v-on:change="setNewFile"
          @click="onShowFileUpload"
          accept=".csv, .txt"
          :rules="validateFile"
        />
        <span class="file-cta">
          <span class="file-icon">
            <i class="fas fa-upload"></i>
          </span>
          <span class="file-label textFileInput"> Selecione um arquivo* </span>
        </span>
        <span v-if="file" class="fname file-name-max-width">
          {{ file.name }}
        </span>
      </label>
    </div>
    <div class="boxButtonNext">
      <div class="checkBox">
        <b-form-checkbox
          type="checkbox"
          class="checkConfirm"
          @change="enviaIgnoraLinha()"
          v-model="ignoraLinhaDuplicada"
          :id="'checkboxLihasDuplicadas'"
          switch
          active
        />
        <p class="checkText">
          <strong>Ignorar linhas duplicadas neste Lote</strong>
          <span
            class="material-symbols-outlined helpIcon"
            id="helpIconMapeamento"
          >
            help
            <md-tooltip class="md-layout-item md-size-33 md-small-size-33">
              As informações de todas as colunas da linha devem ser iguais para
              a linha ser ignorada
            </md-tooltip>
          </span>
        </p>
      </div>
      <span class="boxButtons">
        <button class="button-backstest" @click="prevStep()">ANTERIOR</button>
        <button
          class="button-backstest"
          :disabled="!file || !this.firstLine || !isFileValid || liberaLayout"
          @click="nextStep()"
        >
          PRÓXIMA
        </button>
        <span>
          <md-progress-spinner
            v-if="liberaLayout"
            :md-diameter="30"
            :md-stroke="2"
            md-mode="indeterminate"
            class="spinner"
          />
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapWritableState, mapActions } from "pinia";
import { useBacktestMemoryStore } from "@/stores/backtestMemory";

export default {
  name: "UploadFile",

  props: {
    opcaoPessoaSelecionada: String,
    liberaLayout: Boolean,
  },

  data() {
    return {
      file: null,
      firstLine: "",
      fileContent: "",
      ignoraLinhaDuplicada: true,
      isFileValid: true,
    };
  },

  methods: {
    setNewFile(event) {
      this.resetMapeamento();
      var input = document.getElementById("child-input");
      this.file = input.files[0];
      this.isFileValid = ["text/csv", "text/plain"].includes(
        event.target.files[0].type
      );

      this.readFile();
    },

    validateFile(value) {
      if (!value || ["text/csv", "text/plain"].includes(value.type)) {
        return true;
      } else {
        return "Only TXT or CSV files allowed";
      }
    },

    readFile() {
      this.$emit("selectFile", this.file);
      this.$emit("resetFile");

      var reader = new FileReader();

      reader.readAsText(this.file, "UTF-8");

      reader.onload = () => {
        var text = reader.result; // the entire file
        const linhas = text.split("\n");
        this.firstLine = linhas[0];
        this.secondLine = linhas[1];
        // this.firstLine = text.split("\n").shift(); // first line
        this.$emit("readFile", this.firstLine, this.secondLine);
        this.setFile(this.file, this.firstLine);
      };
    },
    enviaIgnoraLinha() {
      this.setIgnoraLinhas(this.ignoraLinhaDuplicada);
    },

    onShowFileUpload() {
      this.$refs.file.value = null;
      this.file = null;
    },

    nextStep() {
      if (this.file) {
        this.$router.push("/backtest/nova/4");
      }
    },
    prevStep() {
      this.$router.push("/backtest/nova/2");
    },

    ...mapActions(useBacktestMemoryStore, [
      "setFile",
      "setIgnoraLinhas",
      "resetMapeamento",
    ]),
  },
  computed: {
    ...mapWritableState(useBacktestMemoryStore, [
      "fileMemory",
      "ignoraLinhaDuplicadaMemory",
    ]),
  },
  created() {
    if (this.fileMemory != null) {
      this.file = this.fileMemory;
      this.firstLine = this.firstLineMemory;
      this.isFileValid = ["text/csv", "text/plain"].includes(this.file.type);
      this.readFile();
    }
    if (this.ignoraLinhaDuplicadaMemory.length != true) {
      this.ignoraLinhaDuplicada = this.ignoraLinhaDuplicadaMemory;
      this.enviaIgnoraLinha();
    }
  },
};
</script>

<style lang="scss" scoped>
.field {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75%;
  z-index: 100;

  .file {
    flex-direction: column;
    align-self: center;
    width: 100%;
    height: 80%;

    cursor: pointer;
    justify-content: flex-start;
    position: relative;

    @include mediaQueryMax(large) {
      height: 70%;
    }
    @include mediaQueryMax(medium) {
      height: 80%;
    }
    @include mediaQueryMax(small) {
      height: 70%;
    }
  }
}

.file-input {
  height: 0;
  left: 0;
  outline: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.file-label {
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.file-cta {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f5f5f5;
  color: #4a4a4a;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  box-shadow: none;
  font-size: 1rem;
  line-height: 18px;
  position: relative;
  padding: 1em 3em;
  cursor: pointer;

  &:hover {
    background-color: #eeeeee;
    color: #4a4a4a;
  }

  .file-name {
    border-color: #dbdbdb;
    border-radius: 4px;
    font-size: 1em;
    font-weight: 300;
    padding-left: 1em;
    padding-right: 1em;
    white-space: nowrap;
    display: flex;
    width: 100%;
  }
}

.fname {
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding-left: 2%;
  width: 100%;
}


.checkBox {
  width: 100%;
  min-height: 1rem;
  font-size: 0.9rem;
  @include flex-center(row);
  justify-content: flex-start !important;

  @include mediaQueryMax(small) {
    min-height: 1.5rem;
  }
}
.checkText {
  line-height: 0.8rem;
  margin: 0;
}
.helpIcon {
  cursor: pointer;
  color: #209cee;
  font-size: 1.2rem;
  margin: 0px 0 0 5px;
}
.boxButtonNext {
  display: flex;
  height: 25%;
  justify-content: flex-end;
  align-items: center;

  @include mediaQueryMax(small) {
    flex-direction: column;
  }
}

.textFileInput {
  word-break: break-all;

  @include mediaQueryMax(small) {
    font-size: 0.7rem;
  }
}

.boxButtons {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @include mediaQueryMax(small) {
    flex-direction: column;
  }
}

.spinner {
  position: absolute;
  margin: 10px 0px 0 0;
}
</style>
