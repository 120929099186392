<template>
  <div
    class="opcaoRotaBox"
    :style="
      clicked == rota
        ? 'box-shadow: #209cee 0px 3px 8px;background-color:#209cee;color:white'
        : 'box-shadow: gray 0px 1px 4px;background-color:white;color:#209cee'
    "
    @click="selecaoLinguagem(rota)"
  >
    <span
      class="material-symbols-outlined iconRota"
      
      v-if="rota == 'Box de Modelos'"
    >
    speed
    </span>
    <span
      class="material-symbols-outlined iconRota"
      v-if="rota == 'Box de Atributos'"
    >
      library_books
    </span>

    <span class="textOpcao">{{ rota }}</span>
    <!-- <span class="material-symbols-outlined iconItem" v-if="clicked == rota"
      >check_box</span
    >
    <span class="material-symbols-outlined iconItem" v-if="clicked != rota"
      >check_box_outline_blank</span
    > -->
  </div>
</template>

<script>
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
import { mapWritableState, mapActions } from "pinia";

export default {
  name: "OpcaoRotas",

  components: {},

  props: {
    rota: String,
    clicked: String,
    description: String,
  },

  data() {
    return {};
  },
  methods: {
    selecaoLinguagem(rota) {
      this.$emit("recebeRota", rota);
    },
  },
  computed: {},

  created() {
  },
  
};
</script>

<style lang="scss" scoped>
.opcaoRotaBox {
  width: 15rem;
  height: 3rem;
  background-color: #209cee;
  padding: 0.5rem;
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  border-radius: 10px;
  cursor: pointer;
}

.textOpcao {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 1rem;
  font-weight: 500;
  // position: relative;
  // right: 5%;
}

.iconRota {
  margin: 0 10px 0 10px; 
  font-size: 2.5rem;
  opacity: 0.5;
}
.iconItem {
  margin-left: 10px;
}
</style>