<template>
  <div class="boxBacktest container-fluid align-top">
    <TituloBox :label="'Mapeamento de arquivo: '" :iconLabel="'map'" />
    <!-- <div class="boxExt"> -->
    <div class="field">
      <div class="title-info">Selecione as colunas equivalentes ao layout desejado:</div>
      <div class="fields2" v-if="separadorDefinido">
        <div class="selectCols" v-for="(layout, index) in fileColunsLayout" :key="index">
          <label class="labelInput" v-show="verifyAux[layout]">
            {{ layout }}
          </label>
          <DinamicCheckbox
            @recebeOpcao="recebeOpcao"
            @liberaBtnEnviar="liberaBtnEnviar"
            @checkIfNull="checkIfNull"
            @selecionaFormatoData="selecionaFormatoData"
            @resetFormat="resetFormat"
            :layout="layout"
            :index="index"
            :isRequired="fileColunsLayoutFixed[layout]"
            :fileColuns="fileColuns"
            :fileColunsControl="fileColunsControl"
            :selectedOption="selectedOption"
            :selectedOptionObj="selectedOptionObj"
            :formatoData="formatoData"
            :key="componentKey"
          />
          <div class="dataRefAviso" v-if="layout == 'data_ref' && avisoSafra">
            *Se não mapeada, as informações referentes à Safra irão considerar a data
            corrente.
          </div>
        </div>
      </div>
    </div>
    <div class="fields3">
      <div class="opcoes">
        <span class="texto-box">
          <input
            class="input-checkbox"
            :checked="liberaParametrosAvancados"
            type="checkbox"
            @change="liberarParametrosAvancados()"
          />
          <span class="texto"> Parâmetros avançados </span>
        </span>
        <div class="fields" v-if="liberaParametrosAvancados">
          <div class="doc">
            <label v-if="identificadorPrimario != ''" class="label" for="input-primaria">
              Identificador etiqueta principal
            </label>
            <input
              class="search"
              type="text"
              v-model="identificadorPrimario"
              placeholder="Identificador etiqueta principal"
              id="input-primaria"
              name="input-primaria"
              maxlength="120"
            />
          </div>
          <div class="doc">
            <label
              v-if="identificadorSecundario != ''"
              class="label"
              for="input-primaria"
            >
              Identificadores etiquetas secundárias
            </label>
            <input
              class="search"
              type="text"
              v-model="identificadorSecundario"
              placeholder="Identificadores etiquetas secundárias"
              id="input-primaria"
              name="input-primaria"
              maxlength="120"
            />
          </div>
        </div>
      </div>
      <div class="buttonBox">
        <button class="button-backstest" @click="prevStep">ANTERIOR</button>
        <button
          class="button-backstest"
          :disabled="!liberaEnviar"
          @click="nextStepResume"
        >
          PRÓXIMA
        </button>
      </div>
    </div>

    <div v-if="libera">
      <Loading class="spinner" />
    </div>
  </div>
</template>

<script>
import { mapWritableState, mapActions } from "pinia";
import { useBacktestMemoryStore } from "@/stores/backtestMemory";
import Loading from "@/components/Loading";
import DinamicCheckbox from "@/components/Inputs/DinamicCheckbox";
import TituloBox from "@/components/Visuais/TituloBox.vue";

import "aos/dist/aos.css";

export default {
  name: "UploadDadosMap",

  props: {
    opcaoPessoaSelecionada: String,
    selectedOption: String,
    secondLine: String,
  },
  components: {
    Loading,
    DinamicCheckbox,
    TituloBox,
  },

  data() {
    return {
      file: null,
      firstLine: "",
      separadorDefinido: "",
      fileColuns: [],
      firstLineB: "",
      fileColunsControl: [],
      verify: {},
      verifyAux: {},
      fileColunsLayout: "",
      fileColunsLayoutFixed: {},
      selectedOptionObj: {},
      libera: false,
      liberaEnviar: false,
      layout: "",
      formatoData: {},
      finalHeader: "",
      finalFormat: "",
      escolheFormato: false,
      checkedSelects: true,
      componentKey: 0,
      avisoSafra: false,
    };
  },

  methods: {
    liberarParametrosAvancados() {
      this.liberaParametrosAvancados = !this.liberaParametrosAvancados;
    },

    splitText() {
      this.firstLineB = this.firstLine.replaceAll(/(\r\n|\n|\r|"|')/gm, "");
      this.fileColuns = this.firstLineB.split(this.separadorDefinido);
      this.fileColunsControl = [...this.fileColuns];

      if (this.layoutMemory.CabecalhoEntradaCompleto != undefined) {
        this.fileColunsLayout = this.layoutMemory.CabecalhoEntradaCompleto.split(";");
        this.fileColunsLayoutFixed = this.layoutMemory.CabecalhoEntrada.split(";");

        var aux = this.layoutMemory.CabecalhoEntrada.split(";");
      } else {
        alert("Ops, ocorreu um erro!!");
      }

      for (var index in aux) {
        this.fileColunsLayoutFixed[aux[index]] = true;
      }

      for (var index in this.fileColunsLayout) {
        this.verify[this.fileColunsLayout[index]] = null;
      }
      this.verifyAux = { ...this.verify };
    },

    recebeOpcao(l, e, c, f) {
      this.selectedOptionObj[l] = e;
      this.verify[l] = c;
      this.verifyAux[l] = c;
      this.checkedSelects = f;
      if (l == "data_ref") {
        if (this.checkedSelects == true) {
          this.escolheFormato = true;
        } else {
          this.escolheFormato = false;
        }
      }
      this.setMapeamentoVerify(this.verify);
    },

    checkIfNull(l, c) {
      if (l == "data_ref") {
        this.escolheFormato = false;
        if (c == true) {
          this.avisoSafra = false;
        } else {
          this.avisoSafra = true;
        }
      }
      if (c == true) {
        this.verify[l] = false;
        this.verifyAux[l] = false;
      } else {
        this.selectedOptionObj[l] = null;
        this.verify[l] = true;
        this.verifyAux[l] = false;
      }
      this.setMapeamentoVerify(this.verify);
    },

    liberaBtnEnviar() {
      for (let index in this.verify) {
        if (!this.verify[index]) {
          this.liberaEnviar = false;
          return;
        }
      }
      this.liberaEnviar = true;
    },
    selecionaFormatoData(l, f) {
      this.formatoData[l] = f;
    },

    nextStepResume() {
      for (let x in this.selectedOptionObj) {
        if (x.length > 0 && this.selectedOptionObj[x] != null) {
          this.finalHeader += x + "=" + this.selectedOptionObj[x] + ";";
        }
      }

      for (let x in this.formatoData) {
        if (x.length > 0 && this.formatoData[x] != null) {
          this.finalFormat += x + "=" + this.formatoData[x] + ";";
        }
      }

      this.finalFormat = this.finalFormat.slice(0, -1);
      this.finalHeader = this.finalHeader.slice(0, -1);

      this.$emit("recebeInfoCabecalho", this.finalHeader, this.finalFormat);

      if (this.file) {
        this.$router.push("/backtest/nova/6");
      }
    },

    prevStep() {
      this.$router.push("/backtest/nova/4");
    },

    resetFormat(l) {
      delete this.formatoData[l];
    },

    forceRerender() {
      this.componentKey += 1;
    },

    ...mapActions(useBacktestMemoryStore, [
      "setSeparador",
      "resetMapeamento",
      "setMapeamentoVerify",
    ]),
  },

  computed: {
    ...mapWritableState(useBacktestMemoryStore, [
      "fileMemory",
      "firstLineMemory",
      "layoutMemory",
      "separadorDefinidoMemory",
      "mapeamentoMemoryObj",
      "mapeamentoVerifyMemory",
      "identificadorPrimario",
      "identificadorSecundario",
      "liberaParametrosAvancados",
    ]),
  },

  created() {
    this.file = this.fileMemory;
    this.firstLine = this.firstLineMemory;
    if (this.separadorDefinidoMemory != "") {
      this.separadorDefinido = this.separadorDefinidoMemory;
      this.splitText();
    }
    if (
      this.mapeamentoVerifyMemory != {} &&
      Object.keys(this.mapeamentoVerifyMemory).length > 0
    ) {
      this.verify = this.mapeamentoVerifyMemory;
      this.liberaBtnEnviar();
    }
  },

  async mounted() {},
};
</script>

<style lang="scss" scoped>
.boxBacktest {
  border: 1px solid var(--accent-60);
  background-color: #f9f7ff;
  border-radius: 20px;
  padding: 0 15px 0 15px !important;
  z-index: 2;

  @include flex-column();
  align-items: center;
  // min-height: 300px;
  width: 90% !important;
  box-sizing: content-box;
  flex-wrap: wrap;
  margin: 10px;

  @include mediaQueryMax(small) {
    min-height: 500px;
    width: 85% !important;
  }
}
.boxExt {
  min-height: 300px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mediaQueryMax(small) {
    min-height: 420px;
  }
}
.field {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 12px;
  margin: 0;
  gap: 10px;

  @include mediaQueryMax(small) {
    min-height: 60%;
    flex-direction: column;
  }
}
.fields {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.8rem;

  @include mediaQueryMax(small) {
    flex-direction: column;
  }
}

.fields2 {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  font-size: 12px;

  @include mediaQueryMax(small) {
    width: 100%;
    grid-template-columns: 100%;
  }
}

.fields3 {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  font-size: 0.8rem;
  z-index: 2;

  @include mediaQueryMax(small) {
    flex-direction: column;
  }
}

.opcoes {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  width: 80%;
}
.texto-box {
  height: 55px;
  padding: 10px 0 0 0;
}
.texto {
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
}

.input-checkbox {
  cursor: pointer;
  position: relative;
  top: 2px;
}

.title-info {
  font-weight: 500;
  font-size: 14px;
}

.buttonBox {
  display: flex;
  width: 85%;
  height: 100%;
  justify-content: right;

  @include mediaQueryMax(small) {
    margin: 0 !important;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
  }
}

.selectCols {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.labelInput {
  font-size: small;
  color: #6d7879;
  position: absolute;
  margin: 0%;
}

.dataRefAviso {
  color: rgb(228, 0, 0);
  line-height: 0.5rem;
}

.spinner {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 100;
}

.doc {
  width: 350px;
  height: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 5px;
  flex-direction: column;
  margin: -10px 0 10px 0;
}

.label {
  font-size: 12px;
  font-weight: 300;
  margin: 0 0 0 10px;
  position: relative;
  top: 7px;
  opacity: 0.7;
  color: #646464;
}

.search {
  color: #646464;
  display: flex;
  width: 100%;
  max-width: 300px;
  height: 35px;
  border-width: 0px 0px 1px 0px;
  font-size: small;
  margin: 0 0px;
  padding: 0 10px;
  background-color: transparent;
  border-bottom: 1px solid #cfcfcf;

  &.disabled {
    pointer-events: none;
    color: gray;
  }
}
</style>
