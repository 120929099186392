<link
  rel="stylesheet"
  href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
/>

<template>
  <div class="full container-fluid text-wrap">
    <md-card class="messageField">
      <md-card class="messageFieldInside">
        <TituloBox
          :label="'Layout esperado para ' + abordagemConsulta"
          :iconLabel="'dashboard'"
        />
        <div class="boxLayout" style="display: flex">
          <span>
            <p class="msg">
              <strong> Layout completo: </strong>
              {{ layoutChoosedBox.CabecalhoEntradaCompleto }} <br />
            </p>
            <p class="msg">
              <strong> Layout mínimo: </strong>
              {{ layoutChoosedBox.CabecalhoEntrada }}
            </p>
          </span>
          <span>
            <md-progress-spinner
              v-if="liberaLayout"
              :md-diameter="30"
              :md-stroke="2"
              md-mode="indeterminate"
              style="margin-left: 10px"
            />
          </span>
        </div>
        <md-card class="cardInfo1">
          <h5>Atenção:</h5>
          <ul style="margin-top: 0; margin-bottom: 0">
            <li>
              O arquivo do Lote precisa ter cabeçalho contendo pelo menos o
              layout mínimo.
            </li>
            <li>
              O processamento de Lote permite que existam colunas além das
              existentes no layout. Estas colunas serão transportadas para o
              arquivo final sem alterações.
            </li>
            <li>
              As colunas existentes no arquivo enviado não precisam ter os
              mesmos nomes que as do layout informado.
            </li>
            <li>
              Os separadores aceitos são: vírgula ( , ), ponto e vírgula ( ; ) e
              barra vertical ( | )
            </li>
          </ul>
        </md-card>
        <md-card class="cardInfo2">
          <p class="msg">
            <strong>Formatos de arquivo permitidos: </strong> .txt e .csv
          </p>
        </md-card>
      </md-card>
    </md-card>

    <div class="boxBacktest container-fluid text-wrap">
      <TituloBox
        :label="
          opcaoPessoaSelecionada +
          ' > ' +
          abordagemConsulta +
          ' > ' +
          'Carregamento de Lote:'
        "
        :iconLabel="'cloud_upload'"
      />
      <UploadFile
        @selectFile="selectFile"
        @readFile="readFile"
        @resultadoStatus="resultadoStatus"
        @showErrorStatus="showErrorStatus"
        @resetFile="resetFile"
        :opcaoPessoaSelecionada="opcaoPessoaSelecionada"
        :liberaLayout="liberaLayout"
      />
    </div>
  </div>
</template>

<script>
import { lotesService } from "@/services";
import { mapWritableState, mapActions } from "pinia";
import { useBacktestMemoryStore } from "@/stores/backtestMemory";
import "aos/dist/aos.css";
import UploadFile from "./UploadFile.vue";
import TituloBox from "@/components/Visuais/TituloBox.vue";
// import Loading from "@/components/Loading";

export default {
  name: "UploadDadosBox",

  props: {
    opcaoPessoaSelecionada: String,
  },

  components: {
    UploadFile,
    TituloBox,
  },

  data() {
    return {
      file: null,
      errorAlert: "",
      resultadoLote: null,
      liberaLayout: false,
      firstLineLote: "",
      secondLineLote: "",
      layoutChoosedBox: {},
    };
  },

  methods: {
    selectFile(f) {
      this.file = f;
      this.$emit("selectFile", this.file);
    },

    readFile(t, s) {
      this.firstLineLote = t;
      this.secondLineLote = s;
      this.$emit("readFile", this.firstLineLote, this.secondLineLote);
    },

    resultadoStatus(r) {
      this.resultadoLote = r;
    },

    showErrorStatus(e) {
      this.errorAlert = e;
    },

    resetFile() {
      this.resultadoLote = null;
      this.errorAlert = "";
    },

    async showLayoutBox() {
      this.liberaLayout = true;
      const rotaProduto =
        this.rotaConsulta == "Box de Modelos" ? "v3/modelo" : "v3/box";

      const objetoHistorico = {
        produto: rotaProduto,
        cod_ref: this.abordagemConsulta,
      };
      const query = new URLSearchParams(objetoHistorico);
      this.layoutChoosedBox = await lotesService.recuperaLayoutLotes(
        query.toString()
      );
      this.setLayout(this.layoutChoosedBox);
      this.liberaLayout = false;
    },

    ...mapActions(useBacktestMemoryStore, ["setLayout"]),
  },

  computed: {
    ...mapWritableState(useBacktestMemoryStore, ["abordagemConsulta", "rotaConsulta"]),
  },

  created() {
    this.showLayoutBox();
  },
};
</script>

<style lang="scss" scoped>
.full {
  display: flex;
  flex-direction: row;

  @include mediaQueryMax(medium) {
    flex-direction: column;
    align-items: center;
  }
  @include mediaQueryMax(small) {
    flex-direction: column;
    align-items: center;
  }
}

.messageField {
  background-color: #f9f7ff;
  border-radius: 5px;
  display: flex;
  min-height: 270px;
  max-height: 100%;
  max-width: 50% !important;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 10px 0 0 !important;
  padding: 10px;

  @include mediaQueryMax(medium) {
    margin: 15px 0px 0 0 !important;
    min-height: 320px;
    min-width: 95% !important;
  }
  @include mediaQueryMax(small) {
    margin: 15px 0px 0 0 !important;
    min-height: 320px;
    min-width: 95% !important;
  }
}

.messageFieldInside {
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0px !important;
  padding: 0 15px 15px 15px !important;

  @include mediaQueryMax(medium) {
    min-height: 100%;
  }
  @include mediaQueryMax(small) {
    min-height: 100%;
  }
}

.msg {
  margin: 0 !important;
  word-break: break-all;
}

.cardInfo1 {
  margin: 10px 10px 0 10px;
  padding: 5px;
  width: 95%;
}

.cardInfo2 {
  margin: 10px 10px 0px 10px;
  padding: 5px;
  width: 95%;
}

.boxBacktest {
  border: 1px solid var(--accent-60);
  background-color: #f9f7ff;
  border-radius: 20px;
  display: flex;
  height: 270px;
  width: 50% !important;
  box-sizing: content-box;
  flex-wrap: wrap;
  flex-direction: row !important;
  align-items: center;
  z-index: 2;

  @include mediaQueryMax(large) {
    height: 300px;
  }

  @include mediaQueryMax(medium) {
    margin: 10px;
    width: 80% !important;
  }
  @include mediaQueryMax(small) {
    height: 380px;
    margin: 10px;
    width: 90% !important;
  }
}
.spinner {
  width: 20px;
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: 30%;
}
</style>
