<template>
  <div class="full container-fluid text-wrap">
    <md-card class="messageField">
      <div class="fields">
        <TituloBox
          :label="'Estrutura do arquivo enviado'"
          :iconLabel="'article'"
        />
        <div class="cardInfo">
          <div class="title-info">Cabeçalho:</div>
          {{ firstLine }}
          <div class="title-info">Primeira linha:</div>
          {{ secondLine }}
        </div>
        <div class="cardInfo2">
          <span class="title-info">Caracter Separador do Cabeçalho:</span>

          <md-field class="selectSeparador">
            <label for="separadorDefinido"
              >Selecione o separador utilizado*
            </label>
            <md-select
              name="separadorDefinido"
              id="separadorDefinido"
              v-model="separadorDefinido"
              @input="inputSeparador"
            >
              <md-option value=",">, (vírgula)</md-option>
              <md-option value=";">; (ponto e vírgula)</md-option>
              <md-option value="|">| (barra vertical)</md-option>
            </md-select>
          </md-field>
          <md-card class="text-center cardDetails" v-if="separadorDefinido">
            <p class="mapDetailslength">
              Foram detectadas
              <strong>{{ fileColuns.length }} </strong>colunas
            </p>
          </md-card>
        </div>
        <div class="table-box" v-if="separadorDefinido">
          <table style="min-width: 100%">
            <thead>
              <tr class="linhas-tabela">
                <th
                  v-for="(header, index) in headers"
                  :key="index"
                  class="itens-tabela"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="linhas-tabela">
                <td
                  v-for="(row, index) in rows"
                  :key="index"
                  class="itens-tabela"
                >
                  {{ row }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="fields2">
        <div class="buttonBox">
          <button class="button-backstest" @click="prevStep">ANTERIOR</button>
          <button
            class="button-backstest"
            :disabled="!liberaEnviar"
            @click="nextStepResume"
          >
            PRÓXIMA
          </button>
        </div>
      </div>
    </md-card>
  </div>
</template>

<script>
import { mapWritableState, mapActions } from "pinia";
import { useBacktestMemoryStore } from "@/stores/backtestMemory";
import Loading from "@/components/Loading";
import TituloBox from "@/components/Visuais/TituloBox.vue";

export default {
  name: "EstruturaArquivo",

  props: {
    firstLine: String,
    secondLine: String,
  },

  components: {
    TituloBox,
  },

  data() {
    return {
      file: null,
      separadorDefinido: "",
      fileColuns: [],
      firstLineB: "",
      fileColunsControl: [],
      verify: {},
      verifyAux: {},
      fileColunsLayout: "",
      fileColunsLayoutFixed: {},
      headers: [],
      rows: [],
      liberaEnviar: false,
    };
  },

  methods: {
    nextStepResume() {
      if (this.file && this.separadorDefinido != "") {
        this.$router.push("/backtest/nova/5");
      }
    },

    prevStep() {
      this.$router.push("/backtest/nova/3");
    },

    inputSeparador() {
      this.resetMapeamento();
      this.resetMap();
      this.splitText();
    },

    splitText() {
      this.liberaEnviar = false;
      this.firstLineB = this.firstLine.replaceAll(/(\r\n|\n|\r|"|')/gm, "");
      this.fileColuns = this.firstLineB.split(this.separadorDefinido);

      this.headers = this.firstLineB.split(this.separadorDefinido);
      this.rows = this.secondLine.split(this.separadorDefinido);

      this.fileColunsControl = [...this.fileColuns];
      this.setSeparador(this.separadorDefinido);
      this.$emit("recebeColunas", this.fileColuns, this.separadorDefinido);

      if (this.layoutMemory.CabecalhoEntradaCompleto != undefined) {
        this.fileColunsLayout =
          this.layoutMemory.CabecalhoEntradaCompleto.split(";");
        this.fileColunsLayoutFixed =
          this.layoutMemory.CabecalhoEntrada.split(";");

        var aux = this.layoutMemory.CabecalhoEntrada.split(";");
      } else {
        alert("Ops, ocorreu um erro!!");
      }

      for (var index in aux) {
        this.fileColunsLayoutFixed[aux[index]] = true;
      }

      for (var index in this.fileColunsLayout) {
        this.verify[this.fileColunsLayout[index]] = null;
      }
      this.verifyAux = { ...this.verify };
      this.liberaEnviar = true;
    },

    resetMap() {
      this.verify = {};
      this.verifyAux = {};
    },

    ...mapActions(useBacktestMemoryStore, [
      "setSeparador",
      "resetMapeamento",
      "setMapeamentoVerify",
    ]),
  },

  computed: {
    ...mapWritableState(useBacktestMemoryStore, [
      "fileMemory",
      "firstLineMemory",
      "layoutMemory",
      "separadorDefinidoMemory",
    ]),
  },

  created() {
    this.file = this.fileMemory;
    this.firstLine = this.firstLineMemory;

    if (this.separadorDefinidoMemory != "") {
      this.separadorDefinido = this.separadorDefinidoMemory;
      this.splitText();
    }
  },
};
</script>

<style lang="scss" scoped>
.full {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @include mediaQueryMax(medium) {
    flex-direction: column;
    align-items: center;
  }
  @include mediaQueryMax(small) {
    flex-direction: column;
    align-items: center;
  }
}

.messageField {
  border: 1px solid var(--accent-60);
  background-color: #f9f7ff;
  border-radius: 20px;
  padding: 0 15px 0 15px !important;
  z-index: 2;
  min-height: 300px;
  width: 90% !important;
  box-sizing: content-box;
  flex-wrap: wrap;
  margin: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  // @include mediaQueryMax(small) {
  //   min-height: 500px;
  //   width: 85% !important;
  // }
  // @include mediaQueryMax(medium) {
  //   margin: 15px 0px 0 0 !important;
  //   min-height: 320px;
  //   min-width: 95% !important;
  // }
  // @include mediaQueryMax(small) {
  //   margin: 15px 0px 0 0 !important;
  //   min-height: 320px;
  //   min-width: 95% !important;
  // }
}

.fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @include mediaQueryMax(small) {
    width: 100%;
  }
}

.fields2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  font-size: 0.8rem;
  z-index: 2;

  // @include mediaQueryMax(small) {
  // height: 380px;
  // }
}

.cardInfo {
  margin: 0px;
  padding: 10px;
  width: 100%;
  display: flex;
  word-break: break-all;
  flex-direction: column;
  font-size: 12px;
  font-weight: 300;
}
.cardInfo2 {
  margin: 0px;
  padding: 10px;
  width: 100%;
  display: flex;
  word-break: break-all;
  align-items: center;
  gap: 30px;
  font-size: 12px;
  font-weight: 300;

  @include mediaQueryMax(small) {
    flex-direction: column;
  }
}

.title-info {
  font-weight: 500;
  font-size: 14px;
}

.selectSeparador {
  width: 300px;
  max-width: 95%;
}

.cardDetails {
  width: 350px;
  margin: 0 0 0 20px;

  @include mediaQueryMax(small) {
    width: 90%;
  }
}

.mapDetailslength {
  margin: 8px !important;
}

.table-box {
  display: flex;
  overflow: scroll;
  max-height: 500px;
  padding: 10px;
  font-size: 12px;
  font-weight: 300;
  width: 99%;
}

.linhas-tabela {
  display: flex;
  width: 100%;
  gap: 5px;
  min-height: 40px;
}

.itens-tabela {
  flex: 1;
  text-align: center;
  min-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-all;
}

.buttonBox {
  display: flex;
  width: 85%;
  height: 100%;
  justify-content: right;

  @include mediaQueryMax(small) {
    margin: 0 !important;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
  }
}

// .spinner {
//   width: 20px;
//   position: absolute;
//   z-index: 1000;
//   left: 50%;
//   top: 30%;
// }
</style>
