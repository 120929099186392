<template>
  <div class="formatData">
    <label class="labelInput"> Selecione um formato </label>

    <md-field class="formats">
      <md-select
        v-model="selectedFormat"
        name="selectFormat"
        @input="checkFormat"
        :disabled="!checkedSelects"
      >
        <md-option
          v-for="item in listaColunas"
          :key="item.nome_amigavel"
          :value="item.formato_api"
        >
          {{ item.nome_amigavel }} {{ item.exemplo }}
        </md-option>
      </md-select>
    </md-field>
  </div>
</template>

<script>
import colunasLote from "@/helpers/colunasLote.json";
import formatosParametros from "@/helpers/formatosParametros.json";
import { mapWritableState, mapActions } from "pinia";
import { useBacktestMemoryStore } from "@/stores/backtestMemory";

export default {
  name: "MapFormatos",
  data() {
    return {
      selectedFormat: "",
      exemploFormato: "",
      liberaFormato: false,
    };
  },

  props: {
    layout: String,
    selectedOption: String,
    formatoData: Object,
    checkedSelects: Boolean,
    index: Number,
  },

  methods: {
    checkFormat() {
      this.setMapFormato(this.selectedFormat, this.index);
      this.$emit("selecionaFormatoData", this.selectedFormat);
    },

    getFormatosPorLayout() {
      const layout = this.layout;
      const listaFormatos = this.listaFormatos[layout];
      if (listaFormatos && listaFormatos.formatos.length > 0) {
        return listaFormatos.formatos;
      }
      return null;
    },

    getObjetoDecimalPorLayout() {
      const layout = this.layout;
      return this.parametrosConsulta.find(
        (objeto) => objeto.Identificador === layout && objeto.Tipo === "Decimal"
      );
    },
    getObjetoDataPorLayout() {
      const layout = this.layout;
      return this.parametrosConsulta.find(
        (objeto) => objeto.Identificador === layout && objeto.Tipo === "Data"
      );
    },

    ...mapActions(useBacktestMemoryStore, ["setMapFormato"]),
  },

  computed: {
    listaFormatos() {
      return colunasLote;
    },

    listaFormatosParametros() {
      return formatosParametros;
    },

    parametrosConsulta() {
      return this.layoutConsulta.ListaParametros;
    },

    //no prox tipo trocar a forma como é feita a verificação individual
    laytoutDecimal() {
      return this.parametrosConsulta.find(
        (objeto) =>
          objeto.Identificador === this.layout && objeto.Tipo === "Decimal"
      );
    },

    laytoutData() {
      return this.parametrosConsulta.find(
        (objeto) =>
          objeto.Identificador === this.layout && objeto.Tipo === "Data"
      );
    },

    listaColunas() {
      const formatos = this.getFormatosPorLayout();
      if (formatos) {
        this.$emit("liberaFormatoCheck");
        return formatos;
      }

      if (this.getObjetoDecimalPorLayout()) {
        this.$emit("liberaFormatoCheck");
        return this.listaFormatosParametros.Decimal;
      }
      if (this.getObjetoDataPorLayout()) {
        this.$emit("liberaFormatoCheck");
        return this.listaFormatosParametros.Data;
      }
      return null;
    },

    ...mapWritableState(useBacktestMemoryStore, [
      "mapformatoMemoryObj",
      "layoutConsulta",
    ]),
  },
  watch: {
    checkedSelects(newVal, oldVal) {
      if (newVal == true) {
        if (this.listaFormatos[this.layout] != undefined) {
          if (this.listaFormatos[this.layout].formatos.length > 0) {
            this.selectedFormat = this.listaColunas[0].formato_api;

            this.$emit("selecionaFormatoData", this.selectedFormat);
          }
        } else {
          if (this.laytoutDecimal) {
            this.selectedFormat =
              this.listaFormatosParametros.Decimal[0].formato_api;
            this.$emit("selecionaFormatoData", this.selectedFormat);
          }

          if (this.laytoutData) {
            this.selectedFormat =
              this.listaFormatosParametros.Data[0].formato_api;
            this.$emit("selecionaFormatoData", this.selectedFormat);
          }
        }
      } else {
        this.$emit("resetFormat", this.layout);
      }
    },

    // selectedOption(newVal, oldVal) {
    //   if (newVal != "") {
    //     if (this.listaColunas != null) {
    //       this.selectedFormat = this.listaColunas[0].formato_api;
    //     }
    //   }
    // },
  },

  created() {
    if (this.listaFormatos[this.layout] != undefined) {
      if (
        this.listaFormatos[this.layout].formatos.length > 0 &&
        this.checkedSelects
      ) {
        this.selectedFormat = this.listaColunas[0].formato_api;
        this.$emit("selecionaFormatoData", this.selectedFormat);
      }
    }

    if (this.laytoutDecimal && this.checkedSelects) {
      this.selectedFormat = this.listaFormatosParametros.Decimal[0].formato_api;
      this.$emit("selecionaFormatoData", this.selectedFormat);
    }
    if (this.laytoutData && this.checkedSelects) {
      this.selectedFormat = this.listaFormatosParametros.Data[0].formato_api;
      this.$emit("selecionaFormatoData", this.selectedFormat);
    }

    if (this.mapformatoMemoryObj[this.index] != undefined) {
      this.selectedFormat = this.mapformatoMemoryObj[this.index];
      this.$emit("selecionaFormatoData", this.selectedFormat);
    }
  },
};
</script>

<style lang="scss" scoped>
.formats {
  width: 100%;
  display: flex;
  height: 2.8rem !important;
  padding: 0 0 1px 0 !important;
}
.formatData {
  display: flex;
  position: relative;
  width: 43%;
  margin: 0%;
}
.labelInput {
  font-size: small;
  white-space: nowrap;
  color: #707b7c;
  position: absolute;
  left: 0;
  margin: 0%;
}
</style>
